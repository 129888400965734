import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import ViewMap from './pages/ViewMap';
import Sidebar from './Sidebar';
import FamilyTree from './pages/FamilyTree';

const App = () => {
  return (
    <BrowserRouter>
      <div style={{ display: 'flex' }}>
        {/* Sidebar */}
        <Sidebar />
        
        {/* Main content */}
        <div style={{ width: '100%' }}>
          <Routes>
            <Route path="/" element={<ViewMap />} />
            <Route path="/family-tree" element={<FamilyTree />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
