import React from 'react';
import './Sidebar.css';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';


export default props => {
  return (
    <Menu>  
      <Link className="menu-item" to="/">
        Home
      </Link>
      <Link className="menu-item" to="/family-tree">
        Family Tree
      </Link>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/1cpsWMqgQEIlg9Mig3LXygu0vYrpnG3NTzhFlW2ISYSE/edit?usp=sharing" rel="noreferrer">
        "Nocturne"
      </a>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/1wgKdiN2suRxzKhFFlG_t8t9NvtR6CniebgsP0z5v85Y/edit?usp=sharing" rel="noreferrer">
        "The Other Side"
      </a>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/183L0mgs-fVcouo4Rl-tciNokIYJ-q8kwIWf4Vylxfm8/edit?usp=sharing" rel="noreferrer">
        "Schooled"
      </a>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/1BTl6vD-Ofg7bQQUkIpYhh038ujtAVUWKxUggYbz7mWE/edit?usp=sharing" rel="noreferrer">
        "A Night of Quiet Stars"
      </a>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/1cWpE_xVNinLk3EBlF0DA_bseR2sqklrR4SsM3BFeTwA/edit?usp=sharing" rel="noreferrer">
        "Immaculate Mother"
      </a>
      <a className="menu-item" target='_blank' href="https://docs.google.com/document/d/1n0ADZJFqigY_CISS--xUaW2phm3_DErmeqQpnURaAaU/edit?usp=sharing" rel="noreferrer">
        "Renewal"
      </a>
    </Menu>
  );
};
